<template>
  <div>
    <order></order>
  </div>
</template>

<script>
import order from "@/components/order.vue";
export default {
  data() {
    return {};
  },
  components: {
    order,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
</style>